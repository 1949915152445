<template>
  <div class="posts container">
    <div v-if="posts.length">
      <div class="post" v-for="post in posts" :key="post.post_link">

        <div class="d-flex justify-content-between">
          <p>
            <!-- <span class="label">Channel</span> <br> -->
            <img v-if="post.media_type === 1" src="@/assets/images/icon-youtube.svg" height="13" class="icon" />
            <img v-if="post.media_type === 2" src="@/assets/images/icon-instagram.svg" height="13" class="icon" />
            <strong>{{ post.channel_name }}</strong>
          </p>
          <p>
            <span class="tag rejected" v-if="post.admin_status === 2">Rejected</span>
            <span class="tag pending" v-if="post.admin_status === 3">Under Review</span>
            <span class="tag rejected" v-if="post.admin_status === 4">Re-Submit</span>
            <span class="tag approved" v-if="post.admin_status === 5">Approved</span>
          </p>
        </div>

        <!-- <p>
          <span class="label">Remarks</span> <br>
          <strong>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</strong>
        </p> -->

        <!-- <p>
          <span class="label">Post Link</span> <br>
          <strong>
            <a :href="post.post_link" target="_blank">{{post.post_link}}</a>
          </strong>
        </p> -->

        <div class="d-flex justify-content-between">

          <p>
            <span class="label">Views</span> <br>
            <strong>{{ post.view_count | formatNumber }}</strong>
          </p>

          <p>
            <span class="label">Likes</span> <br>
            <strong>{{ post.like_count | formatNumber }}</strong>
          </p>

          <!-- <p>
            <span class="label">Comments</span> <br>
            <strong>{{ post.comment_count | formatNumber }}</strong>
          </p> -->

          <p>
            <span class="label">Eng. Rate</span> <br>
            <strong>{{ post.post_eng_rate || 0 }}%</strong>
          </p>

          <p>
            <span class="label">Date</span> <br>
            <strong>{{ post.add_time | dateFormatMonth }}</strong>
          </p>
        </div>

        <p>
          <span class="label">Post Link</span>
          <van-icon name="share-o" size="14" />
          <br>
          <strong>
            <a :href="post.post_link">{{post.post_link}}</a>
          </strong>
        </p>

        <p>
          <span class="label">Remarks</span> <br>
          <strong>{{ post.remark || 'NA' }}</strong>
        </p>

        <van-divider />

      </div>
    </div>

    <van-empty description="No Posts Found!" v-if="!posts.length" image="https://influencer.soocel.com/img/no-campaign.f391a06e.png">
      <!-- <van-button color="#F54153" round size="small" @click="showActionSheet = true">Add New Post</van-button> -->
      <p>Please submit the post link using below "Add Post"</p>
    </van-empty>

  </div>
</template>

<script>
export default {
  props: ['channels', 'campaign', 'posts'],
  data () {
    return {
    }
  }
}
</script>

<style scoped>
  .posts {
    /* position: relative; */
    /* min-height: 50vh; */
  }
  p {
    font-size: 12px;
    margin: 0 0 10px;
    color: #464646;
  }
  p.title strong {
    font-size: 14px;
  }
  .label {
    color: #6B6B6B;
    margin: 0 5px 0 0;
  }
  .icon {
    position: relative;
    top: 2px;
    margin: 0 5px 0 0;
  }
  .van-icon {
    position: relative;
    top: 1px
  }
</style>
