<template>
  <div class="logs">
    <div v-if="logs.length">
      <van-steps direction="vertical" :active="logs.length - 1">
        <van-step v-for="log in logs" :key="log.remark">
          <p>
            {{ log.remark }} <br />
            <small>{{ log.add_time | dateFormatWithTime }}</small> <br />
            <small class="remark" v-if="log.influencer_remark">{{ log.influencer_remark }}</small>
          </p>
        </van-step>
      </van-steps>
    </div>

    <van-empty description="No Logs Found!" v-if="logs.length <= 0 && !loading" image="https://influencer.soocel.com/img/no-campaign.f391a06e.png">
    </van-empty>

  </div>
</template>

<script>
import { getLogs } from '@/api/campaign'

export default {
  data () {
    return {
      loading: false,
      logs: []
    }
  },
  mounted () {
    this.fetchLogs()
  },
  methods: {
    async fetchLogs () {
      this.loading = true
      const result = await getLogs(this.$route.params.campaignId)
      if (result.success) {
        this.logs = result.logs
      } else {
        this.$notify({ type: 'danger', message: result.message })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
  .van-steps {
    margin: 20px 10px;
  }
  /* .van-steps >>> p {
    margin: 0;
  } */
  .remark {
    display: inline-block;
    padding: 4px 8px;
    margin: 5px 0 0;
    border: 1px solid #CCC;
    border-radius: 6px;
    font-style: italic;
    color: #000;
  }
</style>
