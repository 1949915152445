<template>
  <div class="campaign-details">

    <div class="text-center" style="margin: 50px 0 0;" v-if="loading">
      <van-loading color="#f54153" />
    </div>

    <div v-if="!loading && campaign.id">

      <van-image v-if="!loading" :src="campaign.brand_image" class="cover" fit="cover" height="180" width="100%" />

      <div class="gradient">
        <p class="title m-0">
          <strong>{{ campaign.campaign_name }}</strong>
          <br />
          <small>by {{ campaign.company_name }}</small>
        </p>
      </div>

      <van-tabs v-model="activeTab" title-inactive-color="#6b6b6b" title-active-color="#212121" animated sticky>

        <van-tab title="Details">
          <van-divider />
          <Details :campaign="campaign" v-if="campaign.id" />
        </van-tab>

        <van-tab title="Updates" v-if="campaign.channelResult && campaign.channelResult.length">
          <van-divider />
          <Logs />
        </van-tab>

        <van-tab title="Posts" v-if="approvedChannels.length">
          <van-divider />
          <Posts :channels="channels" :campaign="campaign" :posts="posts" />
        </van-tab>

      </van-tabs>

    </div>

    <div v-if="!loading && activeTab !== 1" class="d-flex action-card">
      <div @click="toggleFavCampaign()" :class="campaign.isFav ? 'active' : ''" class="bookmark d-flex justify-content-center align-items-center">
        <van-loading color="#F54153" v-if="favLoading" />
        <img src="@/assets/images/icon-bookmark.svg" width="18" v-else />
      </div>
      <div style="width: calc(90% - 20px);">
        <van-button color="#F54153" round block @click="showActionSheet = true" v-if="activeTab === 0">
          <strong v-if="campaign.channelResult && campaign.channelResult.length">APPLIED</strong>
          <strong v-else>APPLY NOW</strong>
        </van-button>
        <van-button color="#F54153" round block @click="showActionSheet = true" v-if="activeTab === 2">
          <strong>ADD POST</strong>
        </van-button>
      </div>
    </div>

    <van-popup v-model="showActionSheet" round position="bottom">

      <div class="actionContent">

        <div v-if="channels && channels.length">

          <div v-if="activeTab === 0">
            <p class="title mt-1 mb-2">
              <strong>Please select the promotional channel you want to use.</strong>
            </p>

            <van-checkbox-group v-model="selectedChannels" checked-color="#f54153">
              <div v-for="channel in channels" :key="channel.channel_id" class="channel">
                <van-checkbox :name="channel.id" :disabled="channel.status === 1 || channel.status === 2">
                  <span class="d-flex align-items-center">
                    <img v-if="campaign.promo_platform === 1" src="@/assets/images/icon-youtube.svg" height="14" class="icon" />
                    <img v-if="campaign.promo_platform === 2" src="@/assets/images/icon-instagram.svg" height="26" class="icon" />
                    <span>
                      {{ channel.name }}
                      <small v-if="channel.insta_username">
                        <br>
                        {{ channel.insta_username || '' }}
                      </small>
                    </span>
                    <span class="ms-auto">
                      <span class="tag pending" v-if="channel.status === 0">Pending</span>
                      <span class="tag approved" v-if="channel.status === 1">Approved</span>
                      <span class="tag rejected" v-if="channel.status === 2">Rejected</span>
                    </span>
                  </span>
                </van-checkbox>
              </div>
            </van-checkbox-group>

            <van-button @click="apply()" :loading="btnLoading" loading-text="Processing..." :disabled="!selectedChannels.length || btnLoading" color="#F54153" round block style="margin: 30px 0 0;">
              <strong>CONFIRM</strong>
            </van-button>
          </div>

          <div v-if="activeTab === 2">

            <van-form @submit="submitPost">

              <p class="title mt-1 mb-2">
                <strong>Please choose the channel where you published the post, along with the full URL of the post.</strong>
              </p>

              <van-radio-group v-model="postData.channel" checked-color="#f54153" class="mb-2">
                <div v-for="channel in channels" :key="channel.channel_id" class="channel">
                  <van-radio :name="channel" v-if="channel.status === 1">
                    <span class="d-flex align-items-center">
                      <img v-if="campaign.promo_platform === 1" src="@/assets/images/icon-youtube.svg" height="14" class="icon" />
                      <img v-if="campaign.promo_platform === 2" src="@/assets/images/icon-instagram.svg" height="26" class="icon" />
                      <span>
                        {{ channel.name }}
                        <small v-if="channel.insta_username">
                          <br>
                          {{ channel.insta_username || '' }}
                        </small>
                      </span>
                      <span class="ms-auto">
                        <span class="tag pending" v-if="channel.status === 0">Pending</span>
                        <span class="tag approved" v-if="channel.status === 1">Approved</span>
                        <span class="tag rejected" v-if="channel.status === 2">Rejected</span>
                      </span>
                    </span>
                  </van-radio>
                </div>
              </van-radio-group>

              <!-- <van-divider class="mt-1 mb-1" /> -->

              <van-radio-group v-model="postData.isVideo" checked-color="#f54153" direction="horizontal" class="mt-2 mb-2">
                <van-radio :name="1">Video</van-radio>
                <van-radio :name="null">Image</van-radio>
              </van-radio-group>

              <!-- <label>Post URL</label> -->
              <van-field
                v-model="postData.postUrl"
                placeholder="Enter Post URL"
                :rules="[{ required: true, message: 'Post URL is required' }]"
                :disabled="!postData.channel"
              />

              <van-button color="#F54153" round block :loading="addPostLoading" loading-text="Processing..." :disabled="!postData.channel" type="info" native-type="submit">Submit Post</van-button>

            </van-form>
          </div>

        </div>

        <div v-else>
          <p><strong>You must link your social media accounts in order to apply for a campaign.</strong></p>
          <p>As a result, it will be simpler for us to keep track of the metrics for the posts you publish for the campaigns with which you are associated.</p>

          <van-button color="#F54153" round block @click="gotoConnect()" style="margin: 40px 0 0;">
            <strong>CONNECT CHANNELS</strong>
          </van-button>
        </div>
      </div>

    </van-popup>

  </div>
</template>

<script>
import { getInfo, applyCampaign, toggleFavourite, addCampaignPost, getCampaignPosts } from '@/api/campaign'
import { getUserChannels } from '@/api/user'
import Details from '@/components/campaign/Details.vue'
import Posts from '@/components/campaign/Posts.vue'
import Logs from '@/components/campaign/Logs.vue'

export default {
  components: { Details, Posts, Logs },
  data () {
    return {
      activeTab: 0,
      loading: false,
      campaign: {},
      showActionSheet: false,
      channelDialogue: false,
      isApplied: null,
      status: null,
      btnLoading: false,
      channels: [],
      approvedChannels: [],
      selectedChannels: [],
      applyData: {},
      favLoading: false,
      addPostLoading: false,
      postData: {},
      posts: []
    }
  },
  async mounted () {
    if (this.$route.params.campaignId) {
      await this.getCampaignDetails()
      await this.getPosts()
    }
  },
  methods: {
    async getCampaignDetails () {
      this.loading = true
      const data = await getInfo(this.$route.params.campaignId)
      this.campaign = data.campaign
      this.campaign.category = this.campaign.category ? this.campaign.category.split(',') : []
      this.campaign.language = this.campaign.language ? this.campaign.language.split(',') : []
      this.campaign.gender = this.campaign.gender ? this.campaign.gender.split(',') : []
      this.loading = false

      // already applied channels
      this.selectedChannels = data.campaign.channelResult.map(item => item.media_id)

      this.getChannels()
    },

    async getChannels () {
      const data = await getUserChannels()

      if (this.campaign.promo_platform === 1) {
        this.channels = data.channels.ytList
      } else {
        this.channels = data.channels.instaList
      }

      this.channels = this.channels.map(channel => {
        const filter = this.campaign.channelResult.filter(e => e.media_id === channel.id)
        const status = filter.length ? filter[0].status : null
        channel.status = status
        return channel
      })

      this.approvedChannels = this.channels.filter(channel => channel.status === 1)
    },

    async apply () {
      this.btnLoading = true

      const list = this.selectedChannels.map(id => {
        const filter = this.campaign.channelResult.filter(e => e.media_id === id)
        const status = filter.length ? filter[0].status : 0
        const channel = {
          media_id: id,
          status: status
        }
        return channel
      })

      this.applyData = {
        campaignId: this.$route.params.campaignId,
        mediaType: this.campaign.promo_platform,
        mediaId: list
      }
      const data = await applyCampaign(this.applyData)
      if (data.success) {
        this.$notify({ type: 'success', message: data.message })
        this.getCampaignDetails()
        this.showActionSheet = false
        this.isApplied = 1
        this.selectedChannels = []
      } else {
        this.$notify({ type: 'danger', message: data.message })
      }
      this.btnLoading = false
    },

    gotoConnect () {
      if (window.GoToConnect) {
        window.GoToConnect.postMessage('GoToConnect')
      }
    },

    async toggleFavCampaign () {
      this.favLoading = true
      const data = {
        campaignId: this.$route.params.campaignId
      }
      const result = await toggleFavourite(data)
      if (result.success) {
        this.$notify({ type: 'success', message: result.message })
        this.campaign.isFav = !this.campaign.isFav
      } else {
        this.$notify({ type: 'danger', message: result.message })
      }
      this.favLoading = false
    },

    async getPosts () {
      const params = {
        campaignId: this.campaign.campaign_id,
        platform: this.campaign.promo_platform
      }
      const results = await getCampaignPosts(params)

      if (results.success) {
        this.posts = results.campaigns
      }
    },

    async submitPost () {
      this.addPostLoading = true
      const data = {
        campaign_id: this.campaign.campaign_id,
        campaign_type: this.campaign.campaign_type,
        content_type: this.campaign.content_type,
        media_type: this.campaign.promo_platform,
        is_video: this.postData.isVideo,
        channel_id: this.postData.channel.channel_id || this.postData.channel.insta_username,
        media_id: this.postData.channel.id,
        post_link: this.postData.postUrl
      }
      const result = await addCampaignPost(data)
      if (result.success) {
        this.getPosts()
        this.postData = {}
        this.showActionSheet = false
        this.$notify({ type: 'success', message: result.message })
      } else {
        this.$notify({ type: 'danger', message: result.message })
      }
      this.addPostLoading = false
    }
  }
}
</script>

<style scoped>
  .campaign-details {
    position: relative;
    padding-bottom: 80px;
  }
  /* .campaign-details >>> .cover {
    position: fixed;
    top: 0;
  } */
  .gradient {
    background: linear-gradient(to top, black, rgba(255, 0, 0, 0));
    color: #FFF;
    padding: 20px 16px;
    position: absolute;
    top: 95px;
    width: calc(100% - 32px);
  }
  .gradient p {
    font-size: 16px;
    line-height: 20px;
  }

  .van-tabs {
    border-radius: 16px;
    margin: -20px auto 0;
    background: #FFF;
  }
  .van-tabs >>> .van-tabs__nav--line {
    border-radius: 16px;
    padding-bottom: 0;
  }
  .van-tabs >>> .van-tab {
    font-size: 14px;
    padding: 0 16px;
    flex: none;
    border-radius: 16px;
  }
  .van-tabs >>> .van-tabs__line {
    /* width: 20px; */
    bottom: 0;
    height: 4px;
    background: #f54153;
    border-radius: 0;
  }
  .van-tabs >>> .van-tab--active {
    font-weight: normal;
    color: #1f2964;
  }

  .van-divider {
    margin: 0;
  }
  .action-card {
    position: fixed;
    bottom: 0;
    width: calc(100% - 20px);
    background: #FFF;
    padding: 10px;
    box-shadow: -4px 5px 16px rgba(0, 0, 0, 0.4);
    z-index: 20;
  }
  .bookmark {
    width: 44px;
    height: 44px;
    background: rgba(245, 65, 83, 0.07);
    border-radius: 50%;
    margin: 0 10px 0 0;
  }
  .bookmark.active {
    background: rgba(245, 65, 83, 1);
  }
  .bookmark.active img {
    filter: brightness(0) invert(1);
  }
  .actionContent {
    padding: 16px 16px 50px;
  }
  .channel {
    /* border: 1px solid #CCC; */
    /* padding: 10px; */
    margin: 0 0 20px 0;
    border-radius: 8px;
  }
  .channel .icon {
    margin: 0 5px 0 0;
  }
  .channel span {
    line-height: 13px;
  }
  .channel >>> .van-checkbox,
  .channel >>> .van-checkbox__label,
  .channel >>> .van-radio,
  .channel >>> .van-radio__label {
    width: 100%;
  }
  .status-indicators {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
  }
  .tag.fill {
    margin: 0 5px 0 0;
  }
</style>
