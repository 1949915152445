import request from './index'

export const getUser = () => {
  return request({
    method: 'GET',
    url: '/user/info'
  })
}

export const getUserChannels = () => {
  return request({
    method: 'GET',
    url: '/user/channels'
  })
}
