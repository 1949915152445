<template>
  <div class="details">

    <div class="container">

      <!-- <van-divider /> -->

      <!-- <p class="title m-0">
        <strong>{{ campaign.campaign_name }}</strong>
        <br />
        by {{ campaign.company_name }}
      </p> -->

      <div style="margin: 5px 0 0;" class="d-flex align-content-center justify-content-between highlights">
        <div>
          <p>Platform</p>
          <p class="d-flex align-items-center">
            <img v-if="campaign.promo_platform === 1" src="@/assets/images/icon-youtube.svg" height="13" class="icon" />
            <img v-if="campaign.promo_platform === 2" src="@/assets/images/icon-instagram.svg" height="13" class="icon" />
            <strong>{{ campaign.promo_platform | platform }}</strong>
          </p>
        </div>
        <div>
          <p>
            Type <br>
            <strong>{{ campaign.campaign_type | campaignType }}</strong>
          </p>
        </div>
        <div>
          <p>
            Est. Earnings <br>
            <strong>{{ campaign.budget | formatNumber }}</strong>
          </p>
        </div>
        <div>
          <p>
            Timeline <br>
            <strong>{{ campaign.start_date | dateFormatMonth }} ~ {{ campaign.end_date | dateFormatMonth }}</strong>
          </p>
        </div>
      </div>

      <van-divider />

      <p class="title">
        <strong>Description</strong> <br>
        {{ campaign.campaign_desc && campaign.campaign_desc != '' ? campaign.campaign_desc : 'NA' }}
      </p>

      <van-divider />

      <p class="title">
        <strong>Deliverables</strong> <br>
        {{ campaign.deliverable && campaign.deliverable != '' ? campaign.deliverable : 'NA' }}
      </p>

      <van-divider />

      <!-- <p class="title">
        <strong>Instructions</strong> <br>
        {{ campaign.instructions && campaign.instructions != '' ? campaign.instructions : 'NA' }}
      </p>

      <van-divider /> -->

      <p class="title"><strong>Eligibility</strong></p>

      <van-row gutter="10">
        <van-col span="12">
          <p>
            <span class="label">Category</span> <br>
            <strong v-for="(category, index) in campaign.category" :key="category">
              {{ category }}<span v-if="index < (campaign.category.length - 1)">, </span>
            </strong>
          </p>

          <p>
            <span class="label">Gender</span> <br>
            <strong v-for="(gender, index) in campaign.gender" :key="gender">
              {{ gender }}<span v-if="index < (campaign.gender.length - 1)">, </span>
            </strong>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            <span class="label">Language</span> <br>
            <strong v-for="(language, index) in campaign.language" :key="language">
              {{ language }}<span v-if="index < (campaign.language.length - 1)">, </span>
            </strong>
          </p>
          <p>
            <span class="label">Follower Range</span> <br>
            <strong>{{ campaign.min_follower | formatNumber }} - {{ campaign.max_follower | formatNumber }} </strong>
          </p>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>

export default {
  props: ['campaign']
}
</script>

<style scoped>
  p {
    font-size: 12px;
    margin: 0 0 10px;
    color: #464646;
  }
  p.title strong {
    font-size: 14px;
  }
  .highlights p {
    margin: 0;
  }
  .highlights img {
    margin: 0 3px 0 0;
  }
  .label {
    color: #6B6B6B;
  }
  .label {
    color: #6B6B6B;
  }
</style>
